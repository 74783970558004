@charset "UTF-8";
/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~swiper/swiper-bundle.min.css';
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

canvas {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type=search] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background-color: #b3d4fc;
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background-color: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-SemiBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Cardinal Fruit";
  src: url("/assets/fonts/cardinal/cardinalfruitweb-medium.woff2") format("woff2"), url("/assets/fonts/cardinal/cardinalfruitweb-medium.woff") format("woff");
}
@font-face {
  font-family: "Monument Grotesk Mono";
  src: url("/assets/fonts/Monument Grotesk Mono/ABCMonumentGroteskMono-Regular.woff2") format("woff2"), url("/assets/fonts/Monument Grotesk Mono/ABCMonumentGroteskMono-Regular.woff") format("woff");
}
@font-face {
  font-family: "GravityVariable";
  src: url("/assets/fonts/Gravity Variable/ABCGravityVariable.woff") format("woff2"), url("/assets/fonts/Gravity Variable/ABCGravityVariable.woff2") format("woff");
}
@font-face {
  font-family: "ABC Gravity Extended";
  src: url("/assets/fonts/Gravity/ABCGravity-Extended.woff2") format("woff2"), url("/assets/fonts/Gravity/ABCGravity-Extended.woff") format("woff");
}
@font-face {
  font-family: "ABC Monument Grotesk Mono";
  src: url("/assets/fonts/Monument Grotesk Mono/ABCMonumentGroteskMono-Regular.woff2") format("woff2"), url("/assets/fonts/Monument Grotesk Mono/ABCMonumentGroteskMono-Regular.woff") format("woff");
}
.kai-legal {
  font-weight: 300;
}
.kai-legal * {
  font-size: 16px;
}
.kai-legal h1 {
  font-size: 32px;
}
.kai-legal h1 p {
  font-size: 32px;
}
.kai-legal h1 b {
  font-size: 32px;
}
.kai-legal h2 {
  font-size: 28px;
}
.kai-legal h2 p {
  font-size: 28px;
}
.kai-legal h2 b {
  font-size: 28px;
}
.kai-legal h3 {
  font-size: 24px;
}
.kai-legal h3 p {
  font-size: 24px;
}
.kai-legal h3 b {
  font-size: 24px;
}
.kai-legal h4 {
  font-size: 20px;
}
.kai-legal h4 p {
  font-size: 20px;
}
.kai-legal h4 b {
  font-size: 20px;
}
.kai-legal h5 {
  font-size: 16px;
}
.kai-legal h5 p {
  font-size: 16px;
}
.kai-legal h5 b {
  font-size: 16px;
}
.kai-legal h6 {
  font-size: 12px;
}
.kai-legal h6 p {
  font-size: 12px;
}
.kai-legal h6 b {
  font-size: 12px;
}
.kai-legal__header {
  text-align: center;
  font-weight: 500;
}
.kai-legal ol li {
  list-style: decimal;
}
.kai-legal ol li ol li {
  list-style: upper-alpha;
}
.kai-legal ol li ol li ol li {
  list-style: lower-roman;
}
.kai-legal i {
  font-style: italic;
}
.kai-legal b {
  font-weight: 700;
}
.kai-legal ol {
  margin-top: 20px;
  margin-bottom: 20px;
}

.kai-container {
  margin: 0 auto;
  padding: 8px;
}
@media (min-width: 368px) {
  .kai-container {
    padding: 16px 16px;
    max-width: 768px;
  }
}
@media (min-width: 768px) {
  .kai-container {
    padding: 16px 24px;
    max-width: 768px;
  }
}
@media (min-width: 1200px) {
  .kai-container {
    padding: 16px 32px;
    max-width: 1200px;
  }
}
@media (min-width: 1440px) {
  .kai-container {
    padding: 16px 48px;
    max-width: 1440px;
  }
}

h1 {
  font-family: "ABC Gravity Extended", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 120%;
  text-transform: uppercase;
}
@media (min-width: 1440px) {
  h1 {
    font-family: "ABC Gravity Extended", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 100px;
    line-height: 100%;
    text-transform: uppercase;
  }
}

h2 {
  font-family: "Cardinal Fruit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.03em;
}
@media (min-width: 1440px) {
  h2 {
    font-family: "Cardinal Fruit", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 66px;
    letter-spacing: -0.03em;
  }
}

h3 {
  font-family: "Cardinal Fruit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
  letter-spacing: -0.03em;
}
@media (min-width: 1440px) {
  h3 {
    font-family: "Cardinal Fruit", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 32px;
    letter-spacing: -0.03em;
  }
}

h4 {
  font-family: "Cardinal Fruit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.03em;
}
@media (min-width: 1440px) {
  h4 {
    font-family: "Cardinal Fruit", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.03em;
  }
}

p {
  font-family: "ABC Monument Grotesk Mono", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
}
@media (min-width: 1440px) {
  p {
    font-family: "ABC Monument Grotesk Mono", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
  }
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

canvas {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type=search] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background-color: #b3d4fc;
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background-color: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Medium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Campton";
  src: url("/assets/fonts/campton/Campton-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/rubik/Rubik-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
.cui-legal {
  font-weight: 300;
}
.cui-legal__header {
  text-align: center;
  font-weight: 500;
}
.cui-legal ol li {
  list-style: decimal;
}
.cui-legal ol li ol li {
  list-style: upper-alpha;
}
.cui-legal ol li ol li ol li {
  list-style: lower-roman;
}
.cui-legal i {
  font-style: italic;
}
.cui-legal b {
  font-weight: 700;
}
.cui-legal ol {
  margin-top: 20px;
  margin-bottom: 20px;
}

.cui-container {
  margin: 0 auto;
  height: 100%;
  padding: 8px;
}
@media (min-width: 600px) {
  .cui-container {
    padding: 16px 16px;
    max-width: 960px;
  }
}
@media (min-width: 960px) {
  .cui-container {
    padding: 16px 24px;
    max-width: 960px;
  }
}
@media (min-width: 1280px) {
  .cui-container {
    padding: 16px 32px;
    max-width: 1280px;
  }
}
@media (min-width: 1400px) {
  .cui-container {
    padding: 16px 48px;
    max-width: 1400px;
  }
}

.cui-blog {
  margin: 0 auto;
  height: 100%;
  padding: 8px;
}
@media (min-width: 600px) {
  .cui-blog {
    padding: 16px 16px;
    max-width: 960px;
  }
}
@media (min-width: 960px) {
  .cui-blog {
    padding: 16px 24px;
    max-width: 864px;
  }
}
@media (min-width: 1280px) {
  .cui-blog {
    padding: 16px 32px;
    max-width: 864px;
  }
}
@media (min-width: 1400px) {
  .cui-blog {
    padding: 16px 48px;
    max-width: 864px;
  }
}

.contentful-image {
  display: grid;
  margin: 0 auto;
  width: 100%;
  object-fit: cover;
}

.kuc-contentful blockquote {
  margin: 0;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}
.kuc-contentful blockquote:before {
  content: "“";
  color: #FF00F0;
  display: block;
  font-size: 64px;
  line-height: 0.5em;
}
.kuc-contentful blockquote:after {
  content: "”";
  color: #FF00F0;
  display: block;
  font-size: 64px;
  line-height: 0.5em;
}
.kuc-contentful blockquote p {
  font-size: 24px;
  line-height: 1.5em;
  font-family: "Campton", sans-serif;
  font-weight: 700;
}
@media (min-width: 768px) {
  .kuc-contentful blockquote p {
    font-size: 32px;
  }
}
@media (min-width: 1440px) {
  .kuc-contentful blockquote p {
    font-size: 40px;
  }
}
.kuc-contentful h1 {
  font-family: "Campton", sans-serif;
  line-height: 1.5em;
  font-size: 32px;
  font-weight: 500;
}
.kuc-contentful h1 b {
  font-weight: 700;
}
@media (min-width: 768px) {
  .kuc-contentful h1 {
    font-size: 36px;
  }
}
@media (min-width: 1440px) {
  .kuc-contentful h1 {
    font-size: 40px;
  }
}
.kuc-contentful h2 {
  font-family: "Campton", sans-serif;
  line-height: 1.5em;
  font-size: 28px;
  font-weight: 500;
}
.kuc-contentful h2 b {
  font-weight: 700;
}
@media (min-width: 768px) {
  .kuc-contentful h2 {
    font-size: 32px;
  }
}
@media (min-width: 1440px) {
  .kuc-contentful h2 {
    font-size: 36px;
  }
}
.kuc-contentful h3 {
  font-family: "Campton", sans-serif;
  line-height: 1.5em;
  font-size: 24px;
  font-weight: 500;
}
.kuc-contentful h3 b {
  font-weight: 700;
}
@media (min-width: 768px) {
  .kuc-contentful h3 {
    font-size: 28px;
  }
}
@media (min-width: 1440px) {
  .kuc-contentful h3 {
    font-size: 32px;
  }
}
.kuc-contentful h4 {
  font-family: "Campton", sans-serif;
  line-height: 1.5em;
  font-size: 20px;
  font-weight: 500;
}
.kuc-contentful h4 b {
  font-weight: 700;
}
@media (min-width: 768px) {
  .kuc-contentful h4 {
    font-size: 24px;
  }
}
@media (min-width: 1440px) {
  .kuc-contentful h4 {
    font-size: 28px;
  }
}
.kuc-contentful h5 {
  font-family: "Campton", sans-serif;
  line-height: 1.5em;
  font-size: 16px;
  font-weight: 500;
}
.kuc-contentful h5 b {
  font-weight: 700;
}
@media (min-width: 768px) {
  .kuc-contentful h5 {
    font-size: 20px;
  }
}
@media (min-width: 1440px) {
  .kuc-contentful h5 {
    font-size: 24px;
  }
}
.kuc-contentful h6 {
  font-family: "Campton", sans-serif;
  line-height: 1.5em;
  font-size: 12px;
  font-weight: 500;
}
.kuc-contentful h6 b {
  font-weight: 700;
}
@media (min-width: 768px) {
  .kuc-contentful h6 {
    font-size: 16px;
  }
}
@media (min-width: 1440px) {
  .kuc-contentful h6 {
    font-size: 20px;
  }
}
.kuc-contentful p {
  min-height: 1rem;
  color: rgb(40, 50, 76);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin-bottom: 2rem;
}
.kuc-contentful p b {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
}
.kuc-contentful a {
  display: inline-block;
}
.kuc-contentful p, .kuc-contentful a {
  line-height: 1.5em;
  font-size: 15px;
  font-family: "Rubik", sans-serif;
}
@media (min-width: 768px) {
  .kuc-contentful p, .kuc-contentful a {
    font-size: 16px;
  }
}
@media (min-width: 1440px) {
  .kuc-contentful p, .kuc-contentful a {
    font-size: 18px;
  }
}
.kuc-contentful a {
  color: inherit;
  text-decoration: underline;
}
.kuc-contentful a:hover {
  color: inherit;
}
.kuc-contentful ol {
  margin-top: 24px;
  list-style: none;
  counter-reset: ol-counter;
  padding-left: 0;
}
.kuc-contentful ol li {
  display: flex;
  counter-increment: ol-counter;
  margin-bottom: 2em;
}
.kuc-contentful ol li:before {
  font-family: "Campton", sans-serif;
  font-weight: 700;
  margin-right: 1em;
  content: counter(ol-counter);
  color: #0600FF;
  font-size: 32px;
  line-height: 1em;
}

/* This keeps child nodes hidden while the element loads */
model-viewer {
  background-color: #eee;
  overflow-x: hidden;
  --poster-color: #eee;
}

#ar-button {
  background-image: url(../../assets/ar/hand.png);
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 12px 50%;
  background-color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  bottom: 132px;
  padding: 0px 16px 0px 40px;
  font-family: Roboto Regular, Helvetica Neue, sans-serif;
  font-size: 14px;
  color: #4285f4;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  border: 1px solid #DADCE0;
}

#ar-button:active {
  background-color: #E8EAED;
}

#ar-button:focus {
  outline: none;
}

#ar-button:focus-visible {
  outline: 1px solid #4285f4;
}

@keyframes circle {
  from {
    transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg);
  }
}
@keyframes elongate {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(-100px);
  }
}
model-viewer > #ar-prompt {
  position: absolute;
  left: 50%;
  bottom: 175px;
  animation: elongate 2s infinite ease-in-out alternate;
  display: none;
}

model-viewer[ar-status=session-started] > #ar-prompt {
  display: block;
}

model-viewer > #ar-prompt > img {
  animation: circle 4s linear infinite;
}

model-viewer > #ar-failure {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 175px;
  display: none;
}

model-viewer[ar-tracking=not-tracking] > #ar-failure {
  display: block;
}

/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
.kcc-text__h1 {
  font-family: "Cardinal Fruit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  color: #1E1E1E;
}
.kcc-text__h2 {
  font-family: "Cardinal Fruit", sans-serif, Campton;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #1E1E1E;
}
.kcc-text__h3 {
  font-family: "ABC Monument Grotesk Mono", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #1E1E1E;
}
.kcc-text--primary {
  color: #1E1E1E;
}
.kcc-text--secondary {
  color: #878787;
}
.kcc-text--line {
  text-decoration: line-through;
}

.kcc-button {
  height: 48px;
  width: 100%;
  display: grid;
  justify-content: center;
  align-content: center;
  font-family: "ABC Monument Grotesk Mono", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.1px;
}
.kcc-button--primary {
  color: #FFFFFF;
  background-color: #000000;
  border: 1px solid #000000;
}
.kcc-button--secondary {
  color: #000000;
  background: #FFFFFF;
  border: 1px solid black;
}

.kai-skeleton--animated {
  border-radius: 4px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1);
}
.kai-skeleton--animated::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.3) 20%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  content: "";
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

html, body {
  height: 100%;
  margin: 0;
}

* {
  box-sizing: border-box;
}

html * {
  font-family: "Campton", sans-serif;
  font-weight: 400;
  font-style: normal;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill::first-line {
  font-size: 16px !important;
}

.centered {
  display: flex;
  justify-content: center;
}

.mat-expansion-panel {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: none !important;
  border-radius: 0 !important;
  margin: 0 !important;
  background: transparent !important;
}
.mat-expansion-panel:first-of-type {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.mat-expansion-panel-header {
  padding: 10px 16px !important;
  height: auto !important;
}
@media (min-width: 768px) {
  .mat-expansion-panel-header {
    padding: 24px 24px 24px 0 !important;
  }
}

.category .mat-expansion-panel-header {
  padding: 10px 16px 10px 0 !important;
  height: auto !important;
}
@media (min-width: 768px) {
  .category .mat-expansion-panel-header {
    padding: 24px 24px 24px 0 !important;
  }
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  border-radius: 20px;
}

/* Font color */
input.mat-input-element {
  color: black;
  font-weight: 300;
  font-size: 14px;
}

.mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 300;
  font-size: 14px;
}

.mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0 !important;
}

a {
  color: unset;
  text-decoration: none;
  cursor: pointer;
}

a, a:active, a:focus {
  outline: none; /* Works in Firefox, Chrome, IE8 and above */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mat-button {
  font-weight: 300;
}

.ng-select .ng-select-container {
  border: none !important;
  box-shadow: none !important;
}

.ng-dropdown-panel {
  width: 200px !important;
  font-weight: 300;
  font-size: 16px;
}

h1 {
  font-weight: 300;
  letter-spacing: 0;
  font-size: 30px;
  line-height: calc(30px + 8px);
}
@media (min-width: 368px) {
  h1 {
    font-size: 36px;
    line-height: calc(36px + 8px);
  }
}
@media (min-width: 768px) {
  h1 {
    font-size: 56px;
    line-height: calc(56px + 8px);
  }
}
@media (min-width: 1200px) {
  h1 {
    font-size: 72px;
    line-height: calc(72px + 8px);
  }
}
@media (min-width: 1440px) {
  h1 {
    font-size: 76px;
    line-height: calc(76px + 8px);
  }
}

.swiper-container {
  width: 100%;
  max-width: calc(100vw - 16px);
}
@media (min-width: 368px) {
  .swiper-container {
    max-width: calc(100vw - 32px);
  }
}
@media (min-width: 768px) {
  .swiper-container {
    max-width: 500px;
  }
}

.swiper-button-next, .swiper-button-prev {
  color: #C060FF;
}

.mat-snack-bar-container {
  font-size: 14px;
}

li {
  margin-left: 24px;
}

.zebra__label {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2em;
  color: rgba(255, 255, 255, 0.5);
}
@media (min-width: 768px) {
  .zebra__label {
    font-size: 24px;
    line-height: 32px;
  }
}
.zebra__number {
  color: rgb(255, 255, 255);
}

.line {
  text-decoration: line-through;
}

.equation-title {
  font-style: italic;
}

.panels .email-link {
  color: white;
  text-decoration: underline;
}

.email-link {
  color: darkblue;
  text-decoration: underline;
}

.mat-custom-checkbox .mat-checkbox-frame {
  border-color: white !important;
}

.custom-autocomplete {
  max-height: calc(100vh - 60px) !important;
}
@media (min-width: 768px) {
  .custom-autocomplete {
    max-height: 500px !important;
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: transparent;
  border: 1px solid transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: transparent;
}

.mat-form-field.mat-form-field-appearance-standard {
  color: transparent;
}
.mat-form-field .mat-form-field-underline {
  background-color: transparent;
}
.mat-form-field .mat-form-field-ripple {
  background-color: transparent;
}
.mat-form-field:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-select-value-text {
  color: transparent !important;
}
.mat-form-field:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-select-arrow {
  color: transparent !important;
}
.mat-form-field.mat-form-field-appearance-outline .mat-form-field-flex {
  border-bottom: none;
}
.mat-form-field.mat-form-field-appearance-outline .mat-form-field-prefix {
  top: 0;
}
.mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline-start, .mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline-gap, .mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-color: transparent;
}
.mat-form-field.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline-start, .mat-form-field.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline-gap, .mat-form-field.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline-end {
  border-color: rgba(255, 255, 255, 0.1);
}
.mat-form-field.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.mat-form-field.mat-form-field-appearance-outline.mat-form-field-disabled .mat-input-element {
  color: rgba(255, 255, 255, 0.5);
}

a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.media-text {
  font-style: italic;
}
.media-text p {
  font-style: italic;
  font-family: "Campton", sans-serif;
  font-weight: 300;
}

.mat-dialog-container {
  background-color: white;
}

.section-content p {
  font-family: "ABC Monument Grotesk Mono", sans-serif;
}